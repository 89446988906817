import { useState, useEffect } from 'react';

interface ITaxtScramblesProps {
    text: string
}

const TaxtScrambles = (props: ITaxtScramblesProps) => {
    const shuffle = (word: string) => {
        return word
            .split("")
            .sort(() => {
                return 0.6 - Math.random();
            })
            .join("");
    };

    const gen = () => {
        let textArray = [];
        if (props.text) {
            //variations with change in size
            // for (let i = props.text.length; i >= 0; i--) {
            //     let tmp = shuffle(props.text);
            //     tmp = tmp.slice(0, props.text.length - i);
            //     textArray.push(tmp);
            // }
            textArray.push(props.text);
            //variations without change in size
            for (let i = 0; i < 10; i++) {
                textArray.push(shuffle(props.text));
            }
            //normal text
            textArray.push(props.text);
        }
        return textArray;
    };

    const [textArray] = useState(gen);
    const [activeText, setActiveText] = useState(0);
    const [play, setPlay] = useState(false);


    useEffect(() => {
        let interval: any = null;
        if (play && activeText < textArray.length - 1) {
            interval = setInterval(() => {
                setActiveText(activeText + 1);
            }, 90);
        } else if (!play) {
            if (activeText == textArray.length - 1) {
                setActiveText(0);
            }
            setPlay(true);
        }
        return () => clearInterval(interval);
    }, [play, activeText, textArray.length]);
    return (
        <>
            {textArray[activeText]}

        </>
    );
};
export default TaxtScrambles;