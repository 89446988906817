import React, { FC } from 'react';
import { useTranslation } from "next-i18next";
import { useTheme } from '@mui/material/styles';

import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import LinearProgressWithLabel from '../../animations/LinearProgressWithLabel';



interface IAboutWidgetProps {
  locale?: string;
}

const AboutWidget: FC<IAboutWidgetProps> = (props: IAboutWidgetProps) => {
  const theme = useTheme();
  const { t } = useTranslation('home');
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Container id='about' maxWidth='lg' sx={{ mt: 4 }} >
      <Typography component='h1' variant='h1'>
        {t('AboutWidget.title')}
      </Typography>
      <Typography component='p' variant='subtitle1' gutterBottom>
        {t('AboutWidget.subtitle')}
      </Typography>
      <Divider sx={{ my: 2, borderColor: theme.palette.secondary.dark }} />
      <Grid
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        container
        spacing={{ xs: 1, md: 2 }}
      >
        <Grid item xs={12} sm={12} md={9} lg={9}>
          <Typography component='p' gutterBottom>
            {t('AboutWidget.p0')}
          </Typography>
          <Typography component='p' gutterBottom>
            {t('AboutWidget.p1')}
          </Typography>
          <Typography component='p' gutterBottom>
            {t('AboutWidget.p2')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <LinearProgressWithLabel label={`PHP`} value={95} />
          <LinearProgressWithLabel label={`PostgreSQL`} value={90} />
          <LinearProgressWithLabel label={`MySQL`} value={90} />
          <LinearProgressWithLabel label={`React`} value={80} />
          <LinearProgressWithLabel label={`Material UI`} value={98} />
        </Grid>
      </Grid>
    </Container>
  )
}

export default AboutWidget;