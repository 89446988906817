import React, { FC, useState, useEffect } from 'react';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { motion } from 'framer-motion';


interface ILinearProgressWithLabelProps {
    value: number;
    label: string;
}

const LinearWithValueLabel = (props: LinearProgressProps & { value: number, label: string }) => {
    const theme = useTheme();
    return (
        <motion.div
            style={{ height: '100%' }}
            initial='hidden'
            whileInView='visible'
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            variants={{
                visible: { opacity: 1, scale: 1 },
                hidden: { opacity: 0, scale: 0 }
            }}
        >
            <Box sx={{ minWidth: 100, whiteSpace: 'nowrap' }}>
                <Typography variant="body1" color="text.secondary">{props.label}</Typography>

                <Box sx={{ display: 'flex', alignItems: 'center' }}>

                    <Box sx={{ width: '100%', mr: 1 }}>
                        <motion.div
                            animate={{ color: [theme.palette.primary.main , theme.palette.secondary.main] }}
                            transition={{ duration: 1, repeat: Infinity, repeatType: "reverse", }}
                        >
                            <LinearProgress color="inherit" variant="determinate" {...props} />
                        </motion.div>
                    </Box>

                    <Box sx={{ minWidth: 35 }}>
                        <Typography variant="body2" color="text.secondary">{`${Math.round(
                            props.value,
                        )}%`}</Typography>
                    </Box>
                </Box>
            </Box>
        </motion.div>
    );
}

const LinearProgressWithLabel: FC<ILinearProgressWithLabelProps> = (props: ILinearProgressWithLabelProps) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        setProgress(props.value);
        return () => {
            setProgress(0);
        };
    }, [props.value]);

    return (
        <Box sx={{ width: '100%' }}>
            <LinearWithValueLabel value={progress} label={props.label} />
        </Box>
    );
}

export default LinearProgressWithLabel;