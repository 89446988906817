import React, { FC } from 'react';
import { useTranslation } from 'next-i18next';
import { useTheme } from '@mui/material/styles';
import Image from 'next/image';

import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import TaxtScrambles from '../../animations/TaxtScrambles';

import { motion } from 'framer-motion';


interface ITechnologyWidgetProps {
    locale?: string;
}
interface IFadeInWhenVisibleProps {
    children: React.ReactNode
}

const TechnologyWidget: FC<ITechnologyWidgetProps> = (props: ITechnologyWidgetProps) => {
    const theme = useTheme();
    const { t } = useTranslation('home');
    const matches = useMediaQuery(theme.breakpoints.up('md'));


    const FadeInWhenVisible = (props: IFadeInWhenVisibleProps) => {
        return (
            <motion.div
                style={{ height: '100%' }}
                initial='hidden'
                whileInView='visible'
                viewport={{ once: true }}
                transition={{ duration: 0.6 }}
                variants={{
                    visible: { opacity: 1, scale: 1 },
                    hidden: { opacity: 0, scale: 0 }
                }}
            >
                {props.children}
            </motion.div>
        );
    }

    return (
        <Container maxWidth='lg' sx={{ my: 6 }} >
            <Typography component='h1' variant='h1'>
                {t('TechnologyWidget.title')}
            </Typography>
            <Typography component='p' variant='subtitle1' gutterBottom>
                {t('TechnologyWidget.subtitle')}
            </Typography>
            <Divider sx={{ my: 2, borderColor: theme.palette.secondary.dark }} />
            <Grid
                direction={'row'}
                justifyContent='space-between'
                alignItems='stretch'
                container
                spacing={1}
            >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FadeInWhenVisible>
                        <Box sx={{ height: '100%' }}>
                            <CardHeader
                                titleTypographyProps={{ variant: 'h5', textAlign: 'center' }}
                                title={<TaxtScrambles text={t('TechnologyWidget.Card0.title')} />}
                            />
                            <CardContent sx={{ p: 0  }}>
                                <Stack direction='row' spacing={1} justifyContent={'space-evenly'} alignItems='start'>
                                    <Stack direction='column' justifyContent='center' alignItems='center' sx={{ height: matches ? 100 : 70 }}>
                                        <Image
                                            loading='lazy'
                                            src='/icons/react.svg'
                                            alt='React'
                                            title='React'
                                            width={matches ? 80 : 40}
                                            height={matches ? 80 : 40}
                                        />
                                        <Typography variant='body2' align='center' noWrap>
                                            React
                                        </Typography>
                                    </Stack>
                                    <Stack direction='column' justifyContent='center' alignItems='center' sx={{ height: matches ? 100 : 70 }}>
                                        <Image
                                            loading='lazy'
                                            src='/icons/nextjs.svg'
                                            alt='NextJs'
                                            title='NextJs'
                                            width={matches ? 80 : 40}
                                            height={matches ? 80 : 40}
                                        />
                                        <Typography variant='body2' align='center' >
                                            NextJs
                                        </Typography>
                                    </Stack>
                                    <Stack direction='column' justifyContent='center' alignItems='center' sx={{ height: matches ? 100 : 70 }}>
                                        <Image
                                            loading='lazy'
                                            src='/icons/mui.svg'
                                            alt='Material UI'
                                            title='Material UI'
                                            width={matches ? 80 : 40}
                                            height={matches ? 80 : 40}
                                        />
                                        <Typography variant='body2' align='center' noWrap>
                                            Material UI
                                        </Typography>
                                    </Stack>
                                    <Stack direction='column' justifyContent='center' alignItems='center' sx={{ height: matches ? 100 : 70 }}>
                                        <Image
                                            loading='lazy'
                                            src='/icons/framermotion.svg'
                                            alt='Framer Motion'
                                            title='Framer Motion'
                                            width={matches ? 80 : 40}
                                            height={matches ? 80 : 40}
                                        />
                                        <Typography variant='body2' align='center' noWrap>
                                            Framer
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </CardContent>
                        </Box>
                    </FadeInWhenVisible>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} sx={{mt:'1rem'}}>
                    <FadeInWhenVisible>
                        <Box sx={{ height: '100%' }}>
                            <CardHeader
                                titleTypographyProps={{ variant: 'h5', textAlign: 'center' }}
                                title={<TaxtScrambles text={t('TechnologyWidget.Card1.title')} />}
                            />
                            <CardContent sx={{ p: 0 }}>
                                <Stack direction='row' spacing={1} justifyContent='center' alignItems='center'>
                                    <Stack direction='column' justifyContent='center' alignItems='center' sx={{ height: matches ? 100 : 70 }}>
                                        <Image
                                            loading='lazy'
                                            src='/icons/node.svg'
                                            alt='NodeJS'
                                            title='NodeJS'
                                            width={matches ? 80 : 40}
                                            height={matches ? 80 : 40}
                                        />
                                        <Typography variant='body2' align='center' noWrap>
                                            NodeJS
                                        </Typography>
                                    </Stack>
                                    <Stack direction='column' justifyContent='center' alignItems='center' sx={{ height: matches ? 100 : 70 }}>
                                        <Image
                                            loading='lazy'
                                            src='/icons/nestjs.svg'
                                            alt='NestJs'
                                            title='NestJs'
                                            width={matches ? 80 : 40}
                                            height={matches ? 80 : 40}
                                        />
                                        <Typography variant='body2' align='center' noWrap>
                                            NestJs
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </CardContent>
                        </Box>
                    </FadeInWhenVisible>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} sx={{mt:'1rem'}}>
                    <FadeInWhenVisible>
                        <Box sx={{ height: '100%' }}>
                            <CardHeader
                                titleTypographyProps={{ variant: 'h5', textAlign: 'center' }}
                                sx={{ px: 0 }}
                                title={<TaxtScrambles text={t('TechnologyWidget.Card2.title')} />}
                            />
                            <CardContent sx={{ p: 0 }}>
                                <Stack direction='row' spacing={1} justifyContent='center' alignItems='center'>
                                    <Stack direction='column' justifyContent='center' alignItems='center' sx={{ height: matches ? 100 : 70 }}>
                                        <Image
                                            loading='lazy'
                                            src='/icons/github.svg'
                                            alt='GitHub'
                                            title='GitHub'
                                            width={matches ? 80 : 40}
                                            height={matches ? 80 : 40}
                                        />
                                        <Typography variant='body2' align='center' noWrap>
                                            GitHub
                                        </Typography>
                                    </Stack>
                                    <Stack direction='column' justifyContent='center' alignItems='center' sx={{ height: matches ? 100 : 70 }}>
                                        <Image
                                            loading='lazy'
                                            src='/icons/docker.svg'
                                            alt='Docker'
                                            title='Docker'
                                            width={matches ? 80 : 40}
                                            height={matches ? 80 : 40}
                                        />
                                        <Typography variant='body2' align='center' noWrap>
                                            Docker
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </CardContent>
                        </Box>
                    </FadeInWhenVisible>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} sx={{mt:'1rem'}}>
                    <FadeInWhenVisible>
                        <Box sx={{ height: '100%' }}>
                            <CardHeader
                                titleTypographyProps={{ variant: 'h5', textAlign: 'center' }}
                                sx={{ px: 0, }}
                                title={<TaxtScrambles text={t('TechnologyWidget.Card3.title')} />}
                            />
                            <CardContent sx={{ p: 0 }}>
                                <Stack direction='row' spacing={1} justifyContent='center' alignItems='start'>
                                    {props.locale === 'ru'
                                        ?
                                        <>
                                            <Stack direction='column' justifyContent='center' alignItems='center' sx={{ height: matches ? 100 : 70 }}>
                                                <Image
                                                    loading='lazy'
                                                    src='/icons/metrika.svg'
                                                    alt='Яндекс Метрика'
                                                    title='Яндекс Метрика'
                                                    width={matches ? 80 : 40}
                                                    height={matches ? 80 : 40}
                                                />
                                                <Typography variant='body2' align='center' noWrap>
                                                    Метрика
                                                </Typography>
                                            </Stack>
                                            <Stack direction='column' justifyContent='center' alignItems='center' sx={{ height: matches ? 100 : 70 }}>
                                                <Image
                                                    loading='lazy'
                                                    src='/icons/direct.svg'
                                                    alt='Яндекс Директ'
                                                    title='Яндекс Директ'
                                                    width={matches ? 80 : 40}
                                                    height={matches ? 80 : 40}
                                                />
                                                <Typography variant='body2' align='center' noWrap>
                                                    Директ
                                                </Typography>
                                            </Stack>
                                        </>
                                        : <>
                                            <Stack direction='column' justifyContent='center' alignItems='center' sx={{ height: matches ? 100 : 70 }}>
                                                <Image
                                                    loading='lazy'
                                                    src='/icons/googleanalytics.svg'
                                                    alt='Google Analytics'
                                                    title='Google Analytics'
                                                    width={matches ? 80 : 40}
                                                    height={matches ? 80 : 40}
                                                />
                                                <Typography variant='body2' align='center' noWrap>
                                                    Analytics
                                                </Typography>
                                            </Stack>
                                            <Stack direction='column' justifyContent='center' alignItems='center' sx={{ height: matches ? 100 : 70 }}>
                                                <Image
                                                    loading='lazy'
                                                    src='/icons/ads.svg'
                                                    alt='Google Ads'
                                                    title='Google Ads'
                                                    width={matches ? 80 : 40}
                                                    height={matches ? 80 : 40}
                                                />
                                                <Typography variant='body2' align='center' noWrap>
                                                    Ads
                                                </Typography>
                                            </Stack>
                                        </>
                                    }
                                </Stack>
                            </CardContent>
                        </Box>
                    </FadeInWhenVisible>
                </Grid>
            </Grid>
        </Container >
    )
}

export default TechnologyWidget;